<template>
  <section id="reelUpload">
    <b-row class="mt-2">
      <b-col>
        <b-card>
          <!-- HEADER -->
          <b-row>
            <b-col class="d-flex justify-content-between">
              <b-card-header>
                <b-row class="mb-2">
                  <b-col>
                    <h2>{{ $t('reels.reelUpload') }}</h2>
                    <h6 class="text-primary">
                      {{ $t('procFile') }}
                    </h6>
                  </b-col>
                </b-row>
              </b-card-header>
              <!-- UPLOAD BUTTON | CLOSE CROSS -->
              <div
                class="d-flex"
                style="padding: 1.5rem 1rem"
              >
                <b-col v-if="method == 'BUK'">
                  <b-button
                    size="md"
                    class="text-nowrap"
                    variant="success"
                    @click.stop="event => validationFormInfo()"
                  >
                    {{ $t('reels.uploadReels') }}
                  </b-button>
                </b-col>
                <b-col class="pr-0">
                  <feather-icon
                    icon="XCircleIcon"
                    size="30"
                    class="cursor-pointer text-danger"
                    @click="cleanUpload"
                  />
                </b-col>
              </div>
            </b-col>
          </b-row>

          <b-card-body>
            <validation-observer
              ref="infoRules"
              tag="form"
            >
              <b-table
                id="table"
                :striped="true"
                sticky-header="500px"
                :no-border-collapse="true"
                responsive="sm"
                fixed
                :items="method == 'BUK' ? filesUpload : urlsUpload"
                :fields="columns"
              >
                <!-- THUMBNAIL -->
                <template #cell(icon)="data">
                  <div
                    class="embed-responsive embed-responsive-16by9"
                  >
                    <b-img
                      :id="data.item.key"
                      class="reel-display"
                      loading="lazy"
                      :src="data.item.thumbnail ? data.item.thumbnail : require('@/assets/images/backend/placeholder-video.png')"
                      alt="image"
                    />
                  </div>
                </template>
                <!-- REEL NAME -->
                <template #cell(name)="data">
                  <div class="text-nowrap align-items-center">
                    <validation-provider
                      v-slot="validationContext"
                      name="Name"
                      :rules="{
                        required: true,
                        min: 3,
                        regex: /^[a-zA-Z0-9\s.,'!?()-áéíóú]+$/
                      }"
                    >
                      <b-form-input
                        :id="`name-${data.item.key}`"
                        v-model="data.item.newName"
                        :state="validationContext.errors.length > 0 ? false : null"
                        :placeholder="$t('resources.tableHeader.name')"
                        maxlength="150"
                      />
                      <small class="text-danger">{{ fix(validationContext) }}</small>
                    </validation-provider>
                  </div>
                </template>
                <!-- DESCRIPTION -->
                <template #cell(description)="data">
                  <div class="text-nowrap">
                    <validation-provider
                      v-slot="{ errors }"
                      name="description"
                      :rules="{
                        regex: /^[a-zA-Z0-9\s.,'!?()-áéíóú]*$/
                      }"
                    >
                      <b-form-textarea
                        :id="`description-${data.item.key}`"
                        v-model="data.item.description"
                        :state="errors.length > 0 ? false : null"
                        :placeholder="$t('resources.tableHeader.description')"
                        maxlength="180"
                        rows="2"
                        no-resize
                        class="overflow-scroll"
                      />
                    </validation-provider>
                  </div>
                </template>
                <!-- UPLOAD METHOD -->
                <!-- eslint-disable-next-line vue/no-unused-vars -->
                <template #cell(method)="data">
                  <div class="text-nowrap">
                    <template v-if="method == 'BUK'">
                      <p>{{ $t('reels.bukMethod') }}</p>
                    </template>
                    <template v-else-if="method == 'DIR'">
                      <p>{{ $t('url') }}</p>
                    </template>
                  </div>
                </template>
                <!-- BUTTONS -->
                <template #cell(buttons)="data">
                  <div
                    class="text-nowrap d-flex justify-content-end"
                    style="max-width:100%"
                  >
                    <b-row class="form-group">
                      <!-- UPLOAD URL REEL -->
                      <b-col v-if="method == 'DIR'">
                        <b-button
                          size="md"
                          variant="success"
                          @click.stop="event => validateInputs(data.item.key)"
                        >
                          {{ $t('reels.uploadReel') }}
                        </b-button>
                      </b-col>
                      <b-col class="d-flex align-items-center">
                        <feather-icon
                          icon="XCircleIcon"
                          size="30"
                          class="cursor-pointer text-danger"
                          @click="cleanElement(data.item.key)"
                        />
                      </b-col>
                    </b-row>
                  </div>
                </template>
              </b-table>
              <b-overlay
                :show="busy"
                no-wrap
                variant="transparent"
                :opacity="1.00"
                :blur="'2px'"
                rounded="lg"
              >
                <template #overlay>
                  <div class="text-center">
                    <feather-icon
                      icon="UploadIcon"
                      font-scale="3"
                      size="30"
                    />
                    <br>
                    {{ $t('reels.uploading') }}
                  </div>
                </template>
              </b-overlay>
            </validation-observer>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BButton,
  BCard,
  BCardBody,
  BCardHeader,
  BCol,
  BFormInput,
  BFormTextarea,
  BImg,
  BOverlay,
  BRow,
  BTable,
} from 'bootstrap-vue'
import { Video } from 'video-metadata-thumbnails'
import { showToast, messageError } from '@/store/functions'
import { getUserData } from '@/auth/utils'
import axios from '@axios'
import { required } from '@validations'
import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate'

export default {
  components: {
    BButton,
    BCard,
    BCardBody,
    BCardHeader,
    BCol,
    BFormInput,
    BFormTextarea,
    BImg,
    BOverlay,
    BRow,
    BTable,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    filesUpload: {
      type: Array,
      default: null,
    },
    urlsUpload: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      busy: false,
      columns: [
        { key: 'icon', label: '' },
        { key: 'name', label: this.$t('nameFile') },
        { key: 'description', label: this.$t('resources.tableHeader.description') },
        { key: 'method', label: this.$t('reels.uploadMethod') },
        { key: 'buttons', label: '' },
      ],
      headers: null,
      method: '',
      required,
      uploadedReels: [],
      userData: getUserData(),
    }
  },
  created() {
    if (this.urlsUpload.length > 0) {
      this.method = 'DIR'
    } else if (this.filesUpload.length > 0) {
      this.method = 'BUK'
      this.$swal({
        title: this.$t('dataGeneric.atention'),
        text: this.$t('reels.uploadWarning'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('next'),
        cancelButtonText: this.$t('Edit'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.uploadMultipleReels()
        }
      })
    }
  },
  mounted() {
    this.getHeaders()
    this.setThumbnails()
  },
  methods: {
    cleanElement(key) {
      let reel
      if (this.method === 'DIR') {
        reel = this.urlsUpload.filter(element => element.key !== key)
      } else if (this.method === 'BUK') {
        reel = this.filesUpload.filter(element => element.key !== key)
      }
      this.$emit('emptyReels', reel, this.method)
    },
    cleanUpload() {
      this.$emit('cleanResourcesUploaded')
    },
    fix(data) {
      let message
      const keyExists = Object.keys(data.failedRules)
      if (keyExists.includes('required')) {
        message = this.$t('purchases.nameFormValid')
      }
      if (keyExists.includes('min')) {
        message = this.$t('purchases.nameFormThreeCaract')
      }
      return message
    },
    getHeaders() {
      let defaultLang = 'es'
      try {
        defaultLang = this.userData.profile.client.defaultLanguage
      } catch (error) {
      // eslint-disable-next-line
      console.log(error)
      }
      this.headers = {
        'Accept-Language': defaultLang,
        'Content-Language': defaultLang,
      }
    },
    setThumbnails() {
      if (this.method === 'DIR') {
        this.urlsUpload.forEach(async element => {
          const blob = await fetch(element.url).then(response => response.blob())
          const video = new Video(blob)

          video
            .getThumbnails({ quality: 0.6 })
            .then(thumbnails => {
              // eslint-disable-next-line no-param-reassign
              element.thumbnail = URL.createObjectURL(thumbnails[0].blob)
            })
            .catch(error => {
              // eslint-disable-next-line
              console.log(error)
            })
        })
      } else if (this.method === 'BUK') {
        this.filesUpload.forEach(element => {
          const video = new Video(element.file)

          video
            .getThumbnails({ quality: 0.6 })
            .then(thumbnails => {
              // eslint-disable-next-line no-param-reassign
              element.thumbnail = URL.createObjectURL(thumbnails[0].blob)
            })
            .catch(error => {
              // eslint-disable-next-line
              console.log(error)
            })
        })
      }
    },
    uploadMultipleReels() {
      this.filesUpload.forEach(element => this.validateInputs(element.key))
    },
    uploadReel(reel) {
      const { headers } = this
      const data = new FormData()
      const values = {
        name: reel.newName,
        description: reel.description,
        client: this.userData.profile.client.id,
        mediaLocation: this.method,
      }
      let mutation = 'mutation ($name: String!, $description:String, $client: ID!, $mediaLocation: ContentsReelMediaLocationChoices'
      let query = '{ createReel(input:{client:$client, name:$name, description:$description ,mediaLocation:$mediaLocation'
      if (this.method === 'DIR') {
        mutation += ', $url: String'
        query += ', videoUrl:$url'
        values.url = reel.url
      } else {
        data.append('video', reel.file)
      }
      mutation += ')'
      query += `
            }){
              reel{
                id
                name
                description
                createdAt
                numViews
                isActive
                mediaLocation
                videoUrl
              }
            }
          }
        `
      query = mutation + query
      data.append('query', query)
      data.append('variables', JSON.stringify(values))
      axios
        .post('', data, { headers })
        .then(response => {
          messageError(response, this)
          showToast(this.$t('reels.uploadSuccess'), 1, this)
          this.busy = false
          this.cleanElement(reel.key)
          const file = response.data.data.createReel.reel
          const editedFile = {
            id: file.id,
            name: file.name,
            createdAt: file.createdAt,
            fileUrl: file.videoUrl,
            numViews: file.numViews,
            isActive: file.isActive,
            description: file.description,
            mediaLocation: file.mediaLocation,
          }
          this.$emit('recentUpload', editedFile)
        })
        .catch(err => {
          // eslint-disable-next-line no-console
          console.log(err)
          showToast(this.$t('reels.uploadError'), 2, this)
        })
    },
    validateInputs(key) {
      let reel
      if (this.method === 'DIR') {
        reel = this.urlsUpload.find(element => element.key === key)
      } else if (this.method === 'BUK') {
        reel = this.filesUpload.find(element => element.key === key)
      }
      const nameRegex = /^[a-zA-Z0-9\s.,'"!?()-áéíóú]+$/
      const descriptionRegex = /^[a-zA-Z0-9\s.,'"!?()-áéíóú]*$/

      let nameValid
      let descriptionValid
      const nameInput = document.querySelector(`#name-${key}`)
      const descriptionInput = document.querySelector(`#description-${key}`)
      nameInput.classList.remove('is-valid', 'is-invalid')
      descriptionInput.classList.remove('is-valid', 'is-invalid')

      if (nameRegex.test(reel.newName)) {
        nameInput.classList.add('is-valid')
        nameValid = true
      } else {
        nameInput.classList.add('is-invalid')
        nameValid = false
      }
      if (descriptionRegex.test(reel.description)) {
        descriptionInput.classList.add('is-valid')
        descriptionValid = true
      } else {
        descriptionInput.classList.add('is-invalid')
        descriptionValid = false
      }

      if (nameValid && descriptionValid) {
        this.busy = true
        this.uploadReel(reel)
      }
    },
    validationFormInfo() {
      return new Promise((resolve, reject) => {
        this.$refs.infoRules.validate().then(success => {
          if (success) {
            resolve(true)
            this.uploadMultipleReels()
          } else {
            reject()
          }
        })
      })
    },
  },
}
</script>

<style scoped>
#reelUpload .dropContainer {
  border: 3px dashed;
  height: 100%;
}

#reelUpload .card-width {
  width: 200px;
}

#reelUpload .FileWidth {
  width: 150px;
}

#reelUpload .header {
  position: static;
  width: 100%;
  text-align: center;
  /* align-items: center; */
  align-items: baseline;
}

#reelUpload .svg {
  margin: auto;
  z-index: 100;
}

#reelUpload .color {
  background-color: #000000 !important;
}

#reelUpload .actions {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background: rgb(0 15 51 / 73%);
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
}

#reelUpload .actionsFooter {
  background: rgb(0 15 51 / 73%);
  opacity: 1;
  height: 100%;
  transition: opacity 0.2s ease-in-out;
  position: absolute;
  left: 0;
}

#reelUpload .card-body .actions {
  display: -webkit-flex;
  display: flex;
  transition: all 0.2s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 2.75rem;
  padding-right: 0.34375rem;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
}

#reelUpload .card-footer .actionsFooter {
  z-index: 10;
  display: -webkit-flex;
  display: flex;
  transition: all 0.2s ease-in-out;
  position: absolute;
  width: 100%;
  height: 2.75rem;
  padding-right: 0.34375rem;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  left: 0;
  bottom: 0;
}

#reelUpload .actionFooter {
  width: 230px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: -webkit-center;
}

#reelUpload .card-footer {
  border-top: 0;
}

#reelUpload .card-body {
  padding: 1rem;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  min-height: 1px;
}

#reelUpload .card-body h4 {
  font-size: 1.286rem !important;
}

#reelUpload .card .card-title {
  margin-top: 6rem !important;
  font-weight: bold;
}

#acortar {
  overflow: hidden;
  text-overflow: ellipsis;
}

#acortarPadre {
  overflow: hidden;
  white-space: nowrap;
}

.head {
  display: flex;
  flex-direction: column;
}

.closePage {
  display: -webkit-flex;
  display: flex;
  transition: all 0.2s ease-in-out;
  position: absolute;
  top: 5px;
  left: -5px;
  z-index: 100;
  width: 100%;
  height: 2.75rem;
  padding-right: 0.34375rem;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
}

.content_info {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 70%;
  font-weight: bold;
  color: aquamarine;
  font-size: smaller;
}

textarea::-webkit-scrollbar {
  display: none
}
div::-webkit-scrollbar {
  display: none
}

.reel-display {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    border: 0;
}
</style>
